html{
    height: 100vh;
    overflow-x: hidden;
    /*overflow: hidden;*/
}

body {
    -webkit-touch-callout: none;
    /* This would be the option to diallwos user select
    -webkit-user-select: none;
    */
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    font-family: 'Proxima Nova Regular';
    /** background-color: #38B2CB; */
    min-height: 100vh;
    height: 100%;
    overflow: overlay;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 0;
    /*
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*padding-top: constant(safe-area-inset-top);*/
    /*padding-top: env(safe-area-inset-top);*/
    /*padding: 0;*/
}

@font-face {
  font-family: 'Proxima Nova Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./styles/fonts/Proxima-Nova-Regular.eot'); /* IE9 Compat Modes */
  src: local('Proxima Nova Regular'), local('ProximaNovaRegular'), local('Proxima-Nova-Regular'), local('ProximaNova-Regular'),
       url('./styles/fonts/Proxima-Nova-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./styles/fonts/Proxima-Nova-Regular.woff2') format('woff2'), /*Super Modern Browsers */
       url('./styles/fonts/Proxima-Nova-Regular.woff') format('woff'), /* Modern Browsers */
       url('./styles/fonts/Proxima-Nova-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./styles/fonts/Proxima-Nova-Regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: bold;
  font-weight: 700;
  src: url('./styles/fonts/Proxima-Nova-Bold.eot'); /* IE9 Compat Modes */
  src: local('Proxima Nova Bold'), local('ProximaNovaBold'), local('Proxima-Nova-Bold'), local('ProximaNova-Bold'),
       url('./styles/fonts/Proxima-Nova-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./styles/fonts/Proxima-Nova-Bold.woff2') format('woff2'), /*Super Modern Browsers */
       url('./styles/fonts/Proxima-Nova-Bold.woff') format('woff'), /* Modern Browsers */
       url('./styles/fonts/Proxima-Nova-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./styles/fonts/Proxima-Nova-Bold.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Domaine Text Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./styles/fonts/Domaine-Text-Regular.eot'); /* IE9 Compat Modes */
  src: local('Domaine Text Regular'), local('DomaineTextRegular'), local('Domaine-Text-Regular'), local('DomaineText-Regular'),
       url('./styles/fonts/Domaine-Text-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./styles/fonts/Domaine-Text-Regular.woff2') format('woff2'), /*Super Modern Browsers */
       url('./styles/fonts/Domaine-Text-Regular.woff') format('woff'), /* Modern Browsers */
       url('./styles/fonts/Domaine-Text-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./styles/fonts/Domaine-Text-Regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

*,
*::before,
*::after {
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    touch-action: manipulation;
}


input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
input,
textarea {
    font-size: 16px;
}

a:link {  
  text-decoration:none;
  background-color:transparent;
  color:#20A5E6;
}
a:visited {
  text-decoration:none;
  background-color:transparent;
  color:#20A5E6;
}
a:hover {  
  color: #000000;
  background-color:transparent;
  text-decoration:underline;
}
Xa:hover span {  
  color: #000000;
  background-color:transparent;
  text-decoration:underline;
}
Xa:hover Link {  
  color: #000000;
  background-color:transparent;
  text-decoration:underline;
}
a:active { 
  text-decoration:none;
  background-color:transparent;
  color:#39CCCC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
    margin: 80px auto 0 auto;
    max-width: 1200px;
}

.nav-container {
    margin: auto;
}

.page-title {
    text-align: center;
}
p {
  margin-top:0;
  margin-bottom:1rem
 }
h1,
.h1 {
 font-size:4.34782vh;
 font-family: 'Proxima Nova Bold';
 margin-bottom:1.766304vh;
}
h2,
.h2 {
  font-size: 3.5326vh;
  font-family: 'Proxima Nova Bold';
  margin-bottom: 1.766304vh;}
h3,
.h3 {
 font-size: 1.6rem;
 font-family: 'Domaine Text Regular';
 margin-bottom: 0.7vh;
 padding-left: 0px;
}
h4,
.h4 {
 font-size:1.40625rem
}
h5,
.h5 {
 font-size:1.171875rem
}
h6,
.h6 {
  margin-top:0;
  font-size: inherit;
  font-weight: bold;
  margin-bottom:1rem
}